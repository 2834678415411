import { glanceView, getType } from "@lib";
export default {
  data() {
    return {
      // 账号表单信息
      lookForm: {
        name: "",
        type: "",
        content: "",
        subject: "",
        // time: "20210-01-13 14:00:00",
        status: "",
        files: [],
        createDate: "",
        createUser: "",
        publishStateName: "",
      },
    };
  },
  methods: {},
  async created() {
    let id = this.routerParams.id;
    let res = await glanceView({ id: id });
    // this.lookForm = res.result;
    this.lookForm.name = res.result.name;
    this.lookForm.content = res.result.content;
    this.lookForm.files = res.result.files;
    this.lookForm.createUser = res.result.createUser;
    this.lookForm.createDate = res.result.createDate;
    this.lookForm.subject = res.result.subject;
    this.lookForm.publishStateName = res.result.publishStateName;
    let typeArr = await getType();

    for (let index = 0; index < typeArr.result.length; index++) {
      if (res.result.typeCode == typeArr.result[index].code) {
        this.lookForm.type = typeArr.result[index].name;
      }
    }
    /* if (res.result.publishState === 0) {
      this.lookForm.status = "草稿";
    } else if (res.result.publishState === 1) {
      this.lookForm.status = "审核中";
    } else if (res.result.publishState === 2) {
      this.lookForm.status = "审未通过";
    } else if (res.result.publishState === 1000) {
      this.lookForm.status = "已上架";
    } else {
      this.lookForm.status = "已下架";
    } */
  },
};
